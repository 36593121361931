import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';
import moment from 'moment';
import { signIn } from 'next-auth/react';

import { AppStyles } from '@/enums/core';
import { FirebaseStatus } from '@/features/auth/enums';
import { AuthResponse } from '@/features/auth/interface';
import { messages } from '@/features/auth/localization';
import { errors } from '@/localization';

import { auth } from '@/utils/firebaseConfig';
import { handlePhoneSendOpt, handleRegisterWithGoogle } from '@/utils/firebaseOptions';
import { prisma } from '@/utils/prisma';

let recaptchaInstance: RecaptchaVerifier | null = null;
export const handleSendPhoneCode = async (phoneNumber: string): Promise<void | ConfirmationResult | null | AuthResponse> => {
  try {
    const uniqueContainerId = `recaptchaContainer-${new Date().getTime()}`;
    const element = document.createElement('div');
    element.id = uniqueContainerId;
    element.style.position = 'absolute';
    element.style.bottom = '0';
    element.style.zIndex = '2147483647';
    document.body.appendChild(element);

    if (recaptchaInstance) recaptchaInstance.clear();

    recaptchaInstance = new RecaptchaVerifier(
      auth,
      uniqueContainerId,
      { size: 'invisible' },
    );
    await recaptchaInstance.render();

    const wModal = document.getElementById('w-modal');
    if (wModal) {
      wModal.style.display = 'none';
    }

    const phoneCredential = await handlePhoneSendOpt(recaptchaInstance, phoneNumber);

    if (recaptchaInstance) {
      recaptchaInstance.clear();
      recaptchaInstance = null;
    }
    document.body.removeChild(element); // Remove the unique container after use
    // eslint-disable-next-line no-console
    console.log(phoneCredential, 'phonew cdu');
    if (phoneCredential) return phoneCredential;
    return {
      message: messages.errorSendPhoneCode,
      className: AppStyles.TOAST_DANGER,
    };
  } catch (error) {
    return {
      message: errors.tryAgain,
      className: AppStyles.TOAST_DANGER,
    };
  }
};

export const handleGoogleRegister = async ({ parent }:{ parent: number }):Promise<void | AuthResponse> => {
  try {
    const credential = await handleRegisterWithGoogle();

    if (credential && typeof credential !== 'string' && credential.user) {
      const displayName = credential.user?.displayName?.split(' ');

      const options = {
        callbackUrl: '/dashboard',
        email: credential.user?.email,
        password: credential.user?.uid,
        image: credential.user?.photoURL ?? '',
        lastName: displayName?.[1] ?? '',
        name: displayName?.[0] ?? '',
        parent,
        type: 'register',
        vendor: 1,
      };

      await signIn('credentials', options);
    } if (typeof credential === 'string' && credential === FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER) {
      return {
        message: messages.errorPopupClosedByUser,
        className: AppStyles.TOAST_PRIMARY,
      };
    }
    return {
      message: errors.tryAgain,
      className: AppStyles.TOAST_DANGER,
    };
  } catch (error) {
    return {
      message: errors.tryAgain,
      className: AppStyles.TOAST_DANGER,
    };
  }
};

export const verifyResendEmailTries = async (userId: number): Promise<{ canResend: boolean, currentCounter: number }> => {
  try {
    const retrie = await prisma.retries.findFirst({
      where: {
        user_id: userId,
      },
      select: {
        id: true,
        password_counter: true,
        password_date: true,
      },
    });

    if (!retrie) {
      await prisma.retries.create({
        data: {
          user_id: userId,
          password_counter: 2,
          email_counter: 3,
          email_change_counter: 3,
          password_date: new Date(),
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    const passworCounter = retrie?.password_counter ?? 3;
    const currentTime = new Date();
    const passwordDate = retrie.password_date;
    const hoursDifference = passwordDate ? moment(currentTime).diff(moment(passwordDate), 'hours') : 0;

    if (retrie && retrie.password_date === null) {
      await prisma.retries.update({
        where: { id: retrie.id },
        data: {
          user_id: userId,
          password_counter: passworCounter - 1,
          password_date: currentTime,
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    if (retrie && retrie.password_date !== null) {
      if (passworCounter && passworCounter > 0 && (passwordDate === null || hoursDifference < 24)) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { password_counter: passworCounter - 1, password_date: currentTime },
        });

        if (passworCounter - 1 === 0) return { canResend: false, currentCounter: passworCounter - 1 };
        return { canResend: true, currentCounter: passworCounter - 1 };
      }
      if (passworCounter === 0 && hoursDifference < 24) return { canResend: false, currentCounter: passworCounter };
      if (passworCounter === 0 && hoursDifference >= 24) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { password_counter: 2, password_date: currentTime },
        });

        return { canResend: true, currentCounter: 2 };
      }
    }

    return { canResend: false, currentCounter: 0 };
  } catch (error) {
    return { canResend: false, currentCounter: 0 };
  }
};

export const verifyResendEmailVerifyTries = async (userId: number): Promise<{ canResend: boolean, currentCounter: number }> => {
  try {
    const retrie = await prisma.retries.findFirst({
      where: {
        user_id: userId,
      },
      select: {
        id: true,
        email_counter: true,
        email_date: true,
      },
    });

    if (!retrie) {
      await prisma.retries.create({
        data: {
          user_id: userId,
          email_counter: 2,
          email_change_counter: 3,
          password_counter: 3,
          email_date: new Date(),
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    const emailCounter = retrie.email_counter ?? 3;
    const currentTime = new Date();
    const emailDate = retrie.email_date;
    const hoursDifference = emailDate ? moment(currentTime).diff(moment(emailDate), 'hours') : 0;

    if (retrie && retrie.email_date === null) {
      await prisma.retries.update({
        where: { id: retrie.id },
        data: {
          user_id: userId,
          email_counter: emailCounter - 1,
          email_date: currentTime,
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    if (retrie && retrie.email_date !== null) {
      if (emailCounter && emailCounter > 0 && (emailDate === null || hoursDifference < 24)) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { email_counter: emailCounter - 1, email_date: currentTime },
        });

        if (emailCounter - 1 === 0) return { canResend: false, currentCounter: emailCounter - 1 };
        return { canResend: true, currentCounter: emailCounter - 1 };
      }
      if (emailCounter === 0 && hoursDifference < 24) return { canResend: false, currentCounter: emailCounter };
      if (emailCounter === 0 && hoursDifference >= 24) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { email_counter: 2, email_date: currentTime },
        });

        return { canResend: true, currentCounter: 2 };
      }
    }
    return { canResend: false, currentCounter: 0 };
  } catch (error) {
    return { canResend: false, currentCounter: 0 };
  }
};

export const verifyResendEmailChangeTries = async (userId: number): Promise<{ canResend: boolean, currentCounter: number }> => {
  try {
    const retrie = await prisma.retries.findFirst({
      where: {
        user_id: userId,
      },
      select: {
        id: true,
        email_change_counter: true,
        email_change_date: true,
      },
    });

    if (!retrie) {
      await prisma.retries.create({
        data: {
          user_id: userId,
          email_change_counter: 2,
          email_counter: 3,
          password_counter: 3,
          email_change_date: new Date(),
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    const emailCounter = retrie.email_change_counter ?? 3;
    const currentTime = new Date();
    const emailDate = retrie.email_change_date;
    const hoursDifference = emailDate ? moment(currentTime).diff(moment(emailDate), 'hours') : 0;

    if (retrie && retrie.email_change_date === null) {
      await prisma.retries.update({
        where: { id: retrie.id },
        data: {
          user_id: userId,
          email_change_counter: emailCounter - 1,
          email_change_date: currentTime,
        },
      });
      return { canResend: true, currentCounter: 3 };
    }

    if (retrie && retrie.email_change_date !== null) {
      if (emailCounter && emailCounter > 0 && (emailDate === null || hoursDifference < 24)) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { email_change_counter: emailCounter - 1, email_change_date: currentTime },
        });

        if (emailCounter - 1 === 0) return { canResend: false, currentCounter: emailCounter - 1 };
        return { canResend: true, currentCounter: emailCounter - 1 };
      }
      if (emailCounter === 0 && hoursDifference < 24) return { canResend: false, currentCounter: emailCounter };
      if (emailCounter === 0 && hoursDifference >= 24) {
        await prisma.retries.update({
          where: { id: retrie.id },
          data: { email_change_counter: 2, email_change_date: currentTime },
        });

        return { canResend: true, currentCounter: 2 };
      }
    }
    return { canResend: false, currentCounter: 0 };
  } catch (error) {
    return { canResend: false, currentCounter: 0 };
  }
};
