/* eslint-disable no-console */
import { PrismaClient } from '@prisma/client';

const globalForPrisma = globalThis as unknown as {
  prisma: PrismaClient | undefined;
};

export const prisma = globalForPrisma.prisma
?? new PrismaClient({
  log:
  process.env.NODE_ENV === 'development' ? ['query', 'error', 'warn'] : ['error', 'info'],
  datasources: {
    db: {
      url: process.env.PRIVATE_DATABASE_URL,
    },
  },
});

if (process.env.NODE_ENV !== 'production') globalForPrisma.prisma = prisma;
