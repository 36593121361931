import {
  memo,
  // useState
} from 'react';

import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
// import { Eye, EyeOff } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { messages } from '@/features/auth/localization';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl, FormField, FormItem, FormMessage,
} from '@/components/ui/form';
// import { Input } from '@/components/ui/input';
import {
  InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot,
} from '@/components/ui/input-otp';

interface Props {
  action: (values: z.infer<any>) => void,
  disabled: boolean,
  isRegister: boolean,
}

const FormPhoneCodeAndPassword = ({ action, disabled, isRegister }:Props) => {
  const form = useForm<z.infer<any>>({
    defaultValues: {
      opt: '',
    },
    mode: 'onChange',
  });
  // const [showPassword, setShowPassword] = useState(false);

  return (
    <Form
      watch={form.watch}
      getValues={form.getValues}
      getFieldState={form.getFieldState}
      setError={form.setError}
      clearErrors={form.clearErrors}
      setValue={form.setValue}
      trigger={form.trigger}
      formState={form.formState}
      resetField={form.resetField}
      reset={form.reset}
      handleSubmit={form.handleSubmit}
      unregister={form.unregister}
      control={form.control}
      register={form.register}
      setFocus={form.setFocus}
    >
      <form onSubmit={form.handleSubmit(action)} className="space-y-8 mx-[17px] mb-6">
        <div className="flex flex-col zyy gap-2 w-full">
          <FormField
            control={form.control}
            name="opt"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <div className="w-full flex flex-row items-center justify-center">
                      <InputOTP
                        maxLength={6}
                        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                        className="w-full justify-self-center [border:none] [outline:none] bg-gray self-stretch h-10 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-lg flex flex-row items-center py-[15px] px-3.5 box-border font-text-sm-medium font-medium text-sm text-darkslategray min-w-[212px]"
                        onChange={field.onChange}
                        value={field.value}
                        name={field.name}
                        onBlur={field.onBlur}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {/* <FormField
            control={form.control}
            name="password"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder={messages.password}
                        className="w-full [border:none] [outline:none] bg-gray self-stretch h-10 shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] rounded-lg flex flex-row items-center justify-start py-[15px] px-3.5 box-border font-text-sm-medium font-medium text-sm text-darkslategray min-w-[212px]"
                        onChange={field.onChange}
                        value={field.value}
                        name={field.name}
                        onBlur={field.onBlur}
                      />
                      <button
                        type="button"
                        onClick={() => { return setShowPassword(!showPassword); }}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                      </button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          /> */}
        </div>
        <Button disabled={disabled} type="submit" className="button-primary-full">
          <div className=" marketing-button relative text-sm leading-[10px] font-medium font-text-sm-medium text-sivarbet-background text-center inline-block min-w-[35px]">
            {isRegister ? messages.register : messages.login}
          </div>
        </Button>

      </form>
    </Form>
  );
};

export default memo(FormPhoneCodeAndPassword);
