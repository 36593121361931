import promotionImage from './ball-1.png';
import promotionImage2 from './ball-2.png';
import IconBall from './Ball.png';
import bannerBonus from './bannerBonus.png';
import bonoCasinoMovil from './bonoCasinoMovil.jpg';
import bonoCasino from './bonoCasinoWeb.jpg';
import bonos from './bonos.png';
import bonoSport from './bonoSportWeb.jpg';
import bonoSportMovil from './bonosSportMovil.jpg';
import coinsBonus from './coinsBonus.png';
import defaultBannerMovil from './defaultMovil.jpg';
import defaultBanner from './defaultWeb.jpg';
import IconDice from './Dice.png';
import loginBannerMovil from './loginMovil.webp';
import loginBanner from './loginWeb.webp';
import moneybang from './moneybang.png';
import slots from './slots.png';
import sorteos from './sorteos.png';
import sportBonus from './sportBonus.png';
import star from './star.png';
import torneos from './torneos.png';
import IconTrash from './trash.png';

export {
  IconBall, IconDice, IconTrash, promotionImage, promotionImage2, moneybang, slots, star, sorteos, torneos, bonos, coinsBonus, sportBonus, bannerBonus, bonoCasino, bonoSport, bonoCasinoMovil, bonoSportMovil, loginBanner, loginBannerMovil, defaultBanner, defaultBannerMovil,
};
