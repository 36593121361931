import { z } from 'zod';

import { messages } from '@/features/auth/localization';

export const registerPhoneNumber = z.object({
  phone: z.string().min(10, { message: messages.errorInvalidPhoneNumber }),
  ageVerification: z.boolean().default(false).refine((val) => { return val === true; }, { message: messages.errorAgeVerification }),
});

export const registerPhoneNumberAndPass = z.object({
  phone: z.string().min(10, { message: messages.errorInvalidPhoneNumber }),
  ageVerification: z.boolean().default(false).refine((val) => { return val === true; }, { message: messages.errorAgeVerification }),
});

export const registerEmailAndPass = z.object({
  email: z.string().email({ message: messages.errorInvalidEmail }),
  password: z.string().min(8, { message: messages.errorInvalidPassword }),
  ageVerification: z.boolean().default(false).refine((val) => { return val === true; }, { message: messages.errorAgeVerification }),
//   ageVerification: z.boolean().default(false).optional(),
});

export const loginEmailAndPass = z.object({
  email: z.string().email({ message: messages.errorInvalidEmail }),
  password: z.string().min(8, { message: messages.errorInvalidPassword }),
});

export const loginPhone = z.object({
  email: z.string().email({ message: messages.errorInvalidPhoneNumber }),
  password: z.string().min(8, { message: messages.errorInvalidPassword }),
});

export const newPassRecover = z.object({
  password: z.string().min(8, { message: messages.errorInvalidPassword }),
  passwordConfirm: z.string().min(8, { message: messages.errorInvalidPassword }),
}).superRefine(({ password, passwordConfirm }, ctx) => {
  if (password !== passwordConfirm) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: messages.errorPasswordsDoNotMatch,
      path: ['passwordConfirm'],
    });
  }
});

export const phoneRecover = z.object({
  phone: z.string().min(10, { message: messages.errorInInvalidPhoneNumber }),
});

export const otpRecover = z.object({
  otp: z.string().min(6, { message: messages.errorIsRequired }),
});

export const emailRecover = z.object({
  email: z.string().email({ message: messages.errorInvalidEmail }),
});
